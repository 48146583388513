import CandidateDetail, { CandidateTabs } from './Detail';
import classes from './style.module.scss';
import PerformanceStepDropdown from './utils/PerformanceStepDropdown';
import useChangePerformanceStepYear from './utils/useChangePerformanceStepYear';
import useExpandedHandler from './Detail/utils/useExpandedHandler';
import useLoadingText from 'common/hooks/useLoadingText';
import useTooltipKey from './utils/useTooltipKey';
import { IColumn, Loading, OnSelectRow, OnToggleRow, ResponsiveTable, roundTo } from '@optimization/ssi-common';
import { useCallback, useEffect, useMemo } from 'react';
import { useGetVehiclesQuery } from 'app/services/solution';
import {
  usePerformanceStepYearList,
  getDailyRangeColumnV2,
  ChassisType,
  ListVehicleTransformed,
  ListVehicleRowItem,
  ListVehicleEnhanced,
  VehicleSettingsById,
  SetIsWinter,
  SetIsSoH80,
  SetShowTopUp,
  NO_VALUE_SIGN,
} from '@optimization/sa-common';

interface Props {
  className?: string;
  solutionId: string;
  vehicles: ListVehicleEnhanced[];
  selectedCandidates?: string[];
  actionMode: 'multiselect' | 'expandable-rows';
  expandVehicleId?: number;
  candidateTabDefault?: CandidateTabs;
  dataTestid?: string;
  enableYearSelectorForChargingEvents: boolean;
  performanceStepDropdownReadonly: boolean;
  vehicleSettingsById: VehicleSettingsById;
  handleSelectAll?: (checked: boolean) => void;
  handleSelectRow?: OnSelectRow<ListVehicleRowItem>;
  setIsWinter: SetIsWinter;
  setIsSoH80: SetIsSoH80;
  setShowTopUp: SetShowTopUp;
}

const NameCell = ({ vehicle }: { vehicle: ListVehicleTransformed }) => (
  <span data-action-name="cell-candidate-name-non-editable">{vehicle.transformed.chassisIdPresentation}</span>
);

const CandidatesTable = ({
  className,
  solutionId,
  vehicles,
  selectedCandidates,
  expandVehicleId,
  candidateTabDefault,
  dataTestid,
  enableYearSelectorForChargingEvents,
  performanceStepDropdownReadonly,
  actionMode,
  vehicleSettingsById,
  handleSelectAll,
  handleSelectRow,
  setIsWinter,
  setIsSoH80,
  setShowTopUp,
}: Props) => {
  const expandedHandler = useExpandedHandler();

  const { setVehicleIsExpanded } = expandedHandler;

  const vehiclesInSolutionQuery = useGetVehiclesQuery(solutionId);

  const tooltipKey = useTooltipKey({ vehicles });

  const performanceStepYearList = usePerformanceStepYearList({
    vehicles: vehiclesInSolutionQuery.data,
  });

  const onToggleRow: OnToggleRow<ListVehicleRowItem> = useCallback(
    ({ row, isExpanded }) => {
      setVehicleIsExpanded({ isExpanded, vehicleId: row.vehicle.Id });
    },
    [setVehicleIsExpanded],
  );

  useEffect(() => {
    if (expandVehicleId) {
      setVehicleIsExpanded({ isExpanded: true, vehicleId: expandVehicleId });
    }
  }, [expandVehicleId, setVehicleIsExpanded]);

  const { changePerformanceStepYear } = useChangePerformanceStepYear({
    solutionId,
  });

  const allSelected = useMemo(
    () => (vehicles.length ? vehicles.every((vehicle) => selectedCandidates?.includes(vehicle.Id.toString())) : false),
    [vehicles, selectedCandidates],
  );

  const getRowKey = useCallback((row: ListVehicleRowItem) => row.vehicle.Id, []);

  const columns: IColumn<ListVehicleRowItem>[] = useMemo(
    () => [
      {
        columnKey: 'name',
        columnTitle: 'Name',
        render: (row: ListVehicleRowItem) => <NameCell vehicle={row.vehicle} />,
      },
      {
        columnKey: 'type',
        columnTitle: 'Type',
        render: (row: ListVehicleRowItem) => <ChassisType chassisType={row.vehicle.ChassisType} />,
      },
      {
        columnKey: 'launchPeriod',
        columnTitle: 'Period',
        render: (row: ListVehicleRowItem) =>
          row.vehicle.CompositeId ? row.vehicle.transformed.launchPeriodPresentation : NO_VALUE_SIGN,
      },
      {
        columnKey: 'performanceStep',
        columnTitle: 'Performance step',
        customWidth: '175px',
        disablePadding: (row) =>
          Boolean(row.vehicle.CompositeId) && !(actionMode === 'multiselect' || performanceStepDropdownReadonly),
        render: (row: ListVehicleRowItem) =>
          row.vehicle.CompositeId ? (
            <PerformanceStepDropdown<ListVehicleTransformed>
              vehicle={row.vehicle}
              launchPeriod={row.vehicle.Period}
              createdAt={row.vehicle.CreatedAt}
              performanceStep={row.vehicle.PerformanceStep}
              isLoading={false}
              performanceStepYearList={performanceStepYearList}
              changePerformanceStepYear={changePerformanceStepYear}
              isReadonly={actionMode === 'multiselect' || performanceStepDropdownReadonly}
            />
          ) : (
            <div className={classes['next-gen']}>{row.vehicle.transformed.performanceStepPresentation}</div>
          ),
      },
      {
        columnKey: 'designation',
        columnTitle: 'Designation',
        render: (row: ListVehicleRowItem) =>
          row.vehicle.CompositeId && row.vehicle.Designation ? row.vehicle.Designation : NO_VALUE_SIGN,
      },
      {
        columnKey: 'installedEnergy',
        columnTitle: 'Installed energy',
        render: (row: ListVehicleRowItem) =>
          row.vehicle.InstalledEnergyKWh ? `${row.vehicle.InstalledEnergyKWh} kWh` : NO_VALUE_SIGN,
      },
      {
        columnKey: 'energyConsumption',
        columnTitle: 'Energy consumption',
        render: (row: ListVehicleRowItem) =>
          row.vehicle.CompositeId && row.vehicle.transformed.totalEc
            ? `${roundTo(row.vehicle.transformed.totalEc, 2).toFixed(2)} kWh/km`
            : NO_VALUE_SIGN,
      },
      {
        columnKey: 'gtw',
        columnTitle: 'GTW',
        render: (row: ListVehicleRowItem) =>
          row.vehicle.GenericMaxGtwKg ? `${row.vehicle.transformed.gtwPresentation} t` : NO_VALUE_SIGN,
      },
      { ...getDailyRangeColumnV2({ tooltipKey }) },
    ],
    [actionMode, performanceStepDropdownReadonly, performanceStepYearList, tooltipKey, changePerformanceStepYear],
  );

  const rows: ListVehicleRowItem[] = useMemo(
    () =>
      vehicles.map((vehicle) => ({
        vehicle,
        selected: selectedCandidates?.includes(vehicle.Id.toString()),
        expanded: Boolean(expandedHandler.isExpanded[vehicle.Id]),
        childrenWhenExpanded: (
          <CandidateDetail
            vehicle={vehicle}
            solutionId={solutionId}
            candidateTabDefault={candidateTabDefault}
            tooltipKey={tooltipKey}
            expanded={expandedHandler.isExpanded[vehicle.Id]}
            vehicleSettings={vehicleSettingsById[vehicle.Id]}
            setIsWinter={setIsWinter}
            setIsSoH80={setIsSoH80}
            setShowTopUp={setShowTopUp}
            enableYearSelectorForChargingEvents={enableYearSelectorForChargingEvents}
          />
        ),
      })),
    [
      candidateTabDefault,
      enableYearSelectorForChargingEvents,
      selectedCandidates,
      solutionId,
      vehicles,
      expandedHandler,
      tooltipKey,
      vehicleSettingsById,
      setIsWinter,
      setIsSoH80,
      setShowTopUp,
    ],
  );

  const loadingText = useLoadingText({
    isLoadingCandidates: vehiclesInSolutionQuery.isLoading,
  });

  return (
    <>
      <Loading
        isLoading={vehiclesInSolutionQuery.isLoading}
        isError={vehiclesInSolutionQuery.isError}
        loadingText={loadingText}
      />
      <ResponsiveTable<ListVehicleRowItem>
        className={className}
        dataTestid={dataTestid}
        columns={columns}
        rows={rows}
        actionMode={actionMode}
        allSelected={allSelected}
        getRowKey={getRowKey}
        onToggleRow={onToggleRow}
        onSelectRow={handleSelectRow}
        onSelectAll={handleSelectAll}
      />
    </>
  );
};

export default CandidatesTable;
